import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './style.css';


function Hero() {
  
  const [products, setProducts] = useState([]);
  const [banners, setBanners] = useState([]);
  const [defaultbanner,setDefaultBanner] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleCount = 5;

  useEffect(() => {
    fetch('https://waltzify.com/api/Fetch_Category.php')
      .then(response => response.json())
      .then(data => setProducts(data))
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  useEffect(() => {
    fetch('https://waltzify.com/api/fetch_banner.php')
      .then(response => response.json())
      .then(data => setBanners(data))
      .catch(error => console.error('Error fetching banners:', error));
  }, []);

  
  useEffect(() => {
    fetch('https://waltzify.com/api/fetch_default_banner.php')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setDefaultBanner(data);
        } else {
          console.error('Error: Expected an array but received:', data);
          setDefaultBanner([]);
        }
      })
      .catch(error => {
        console.error('Error fetching default banners:', error);
        setDefaultBanner([]); // Default to an empty array if there's an error
      });
  }, []);
  
  

  const prevImage = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, products.length - visibleCount));
  };

  return (
    
    <div className="banner relative max-sm:h-[25rem] max-md:mt-[33vw] max-md:h-[30rem] h-[25rem] lg:h-[70rem] overflow-hidden">
    
      
      <div className = 'lg:h-[40rem] h-[16rem]'>
  {banners.length > 0 ? (
    banners.map((banner, index) => (
      <div key={banner.id || index} className="relative w-full h-[16rem] lg:h-[40rem]">
        <img className="absolute lg:mt-[8rem] object-fill w-[100%] h-[100%] lg:h-full" src={`https://waltzify.com/api/Banner/${banner.image}`} alt={banner.text} />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white">
        {banner.text && (
    <a href={'/allproduct'} className="bg-black bg-opacity-50 p-4 rounded">
        <h2 className="text-xl lg:text-3xl font-bold">{banner.text}</h2>
    </a>
)}
        </div>
      </div>
    ))
  ) : (
    defaultbanner
      .filter((defaultbanner) => defaultbanner.location === "Home Page")
      .map((defaultbanner, index) => (
        <div key={defaultbanner.id || index} className="relative w-full h-[16rem] lg:h-[40rem]">
          <img
            className="absolute lg:mt-[8rem] object-fill w-[100%] h-[100%] lg:h-full"
            src={`https://waltzify.com/api/DefaultBanner/${defaultbanner.image}`}
            alt="default banner"
          />
        </div>
      ))
  )}
</div> 
    


      
      <button onClick={prevImage} className="z-[100] lg:text-3xl absolute -left-4 lg:left-0 top-[17rem] lg:top-[45rem] ml-4 p-2 rounded-full bg-white">
        <FaChevronLeft />
      </button>
      
      <div className="absolute max-sm:top-[7rem] max-md:top-[9rem] lg:mt-[0rem] mt-[5rem] top-[13rem] lg:mt-[0] mt-[12rem] lg:top-[38rem] px-[1rem] flex justify-evenly items-center gap-[2rem] transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentIndex * (100 / visibleCount)}%)` }}>
        {products.map((product, index) => (
          <div key={index} className="no-scrollbar hover:scale-[1.02] transition-all ease-in-out duration-[500ms] rounded-xl group relative w-[7rem] lg:w-[20rem] bg-white">
            <Link to={`/category/${product.cname}`} >
            <div className="relative">
    <img 
        className="group-hover:scale-105 shadow-2xl rounded-xl transition-all ease-in-out duration-[500ms] w-full lg:h-[30rem] h-[10rem] object-cover" 
        src={`https://waltzify.com/api/Category/${product.image}`} 
        alt="" 
    />
   {/*  <p 
  className="text-white transition-all ease-in-out absolute lg:top-[18vw] top-[8vw] lg:w-[20rem] left-0 lg:left-[-6vw] bottom-[20%] text-xs lg:text-3xl font-bold -rotate-90 whitespace-normal overflow-hidden overflow-wrap break-word"
>
  {product.cname}
</p> */}
<p 
  className="text-white transition-all ease-in-out absolute top-[6vw] lg:top-[5vw] left-[20%] bottom-[0%] text-xs lg:text-2xl font-bold -rotate-90 overflow-hidden break-words"
  style={{
    width: '100%', // Allows full width of the text container
    maxWidth: '100%', // Maximum width limit for larger screens
    whiteSpace: 'normal', // Allows text to wrap if it's too long
    lineHeight: '1.2', // Adjusts line spacing for readability
  }}
>
  {product.cname}
</p>

    {/* <p className="text-white transition-all ease-in-out absolute lg:top-[18vw] top-[8vw] lg:w-[20rem] left-0 lg:left-[-6vw] bottom-[20%] whitespace-nowrap text-xs lg:text-3xl font-bold -rotate-90 ">
        {product.cname}
    </p> */}
</div>

          
            </Link>
          </div>
        ))}
        </div> 
  

      
      {currentIndex < products.length - visibleCount && (
        <button onClick={nextImage} className="z-[100] lg:text-3xl absolute top-[17rem] lg:top-[45rem] -right-4 lg:right-0 mr-4 p-2 rounded-full bg-white">
          <FaChevronRight />
        </button>
      )}
    </div>
  );
}

export default Hero;
 